import {Audit} from "./audit";
import React from "react";
import {ContentSectionHeading} from "../content-section-heading/content-section-heading";
interface AuditThemesListProps {
    audit:Audit
}
export const AuditThemesList:React.FC<AuditThemesListProps> = ({audit}) => {
    if(!audit.elCompanyTefts || audit.elCompanyTefts.length == 0) {
        return null;
    }
    let themes = audit.elCompanyTefts
    if(audit.complexErrorStructure != null) {
        themes = audit.elCompanyTefts.filter((theme) => {
            const category = audit.complexErrorStructure.categories.find(c => c.fieldName === theme)
            return !!category
        }).map((theme) => {
            const category = audit.complexErrorStructure.categories.find(c => c.fieldName === theme)
            return category!.name
        })
    }
    return <>
        <ContentSectionHeading>Tema for tilsyn</ContentSectionHeading>
        <div>Følgende er tema for tilsyn:</div>
        <ul>
            {themes.map((teft: string, index: number) => (
                <li key={index}>{teft}</li>
            ))}
        </ul>
    </>
}