import React, { useEffect } from 'react'
import { destroy } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../app/app-store'
import { actionLoadPostalAudit } from '../../other-actions'
import { OtherFormName } from '../../enums/form-name.enum'
import { useParams } from 'react-router-dom'
import { CircularProgress, styled } from '@mui/material'
import { ContentHeading } from '../../../common/content-heading/content-heading'
import { ContentDivider } from '../../../common/content-divider/content-divider'
import { DisplayValue } from '../../../common/display-value/display-value'
import { ContentSectionHeading } from '../../../common/content-section-heading/content-section-heading'
import { getAuditStatus, getAuditType } from 'common/audit/audit-utils'
import { formatDate } from 'utils/format/format-date'
import { AdditionalAuditType } from '../../../common/audit/audit'
import { isFirstStageDocRequest, isSecondStageDocRequest } from './postal-audit-helper'
import {AuditThemesList} from "../../../common/audit/audit-themes-list";
interface RouteParams {
    pin: string
    tenantId: string
    auditId: string
}
type Props = {}
const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}))

const ContentWrapper = styled('div')(({ theme }) => ({
    padding: '16px 40px',
}))
export const PostalAuditInfo: React.FC<Props> = (props) => {
    const audit = useSelector((state: AppState) => state.other.postalAudit)
    const dispatch = useDispatch()
    const { tenantId, auditId, pin } = useParams<RouteParams>()
    useEffect(() => {
        if (tenantId && auditId && pin) {
            dispatch(actionLoadPostalAudit(tenantId, auditId, pin))
        }
        dispatch(destroy(OtherFormName.PostalAudit))
    }, [])
    if (!audit || !audit.metaData) {
        return (
            <ContentHeading>
                <CircularProgress color={'primary'} />
            </ContentHeading>
        )
    }
    const getTitle = () => {
        if (audit.additionalType === AdditionalAuditType.POSTAL_AUDIT) {
            return 'Postalt tilsyn'
        } else {
            return 'Dokumenter før tilsyn'
        }
    }
    const getObjectName = () => {
        if (audit.additionalType === AdditionalAuditType.POSTAL_AUDIT) {
            return audit.targets && audit.targets.length > 0 ? audit.targets[0].specificFireObjectName : '-'
        } else {
            return audit.targets && audit.targets.length > 0 ? audit.targets[0].name : '-'
        }
    }

    return (
        <div>
            <ContentHeading>
                <Header>
                    <span>{getTitle()}</span>
                    <span>{audit.caseNumber}</span>
                </Header>
            </ContentHeading>
            <ContentDivider />
            <ContentWrapper>
                <DisplayValue label={'Type'} value={getAuditType(audit)} />
                <DisplayValue label={'Objekt'} value={getObjectName()} />
                <DisplayValue label={'Opprettet'} value={formatDate(audit.created)} />
                <DisplayValue label={'Status'} value={getAuditStatus(audit)} />
            </ContentWrapper>
            {audit.elCompanyTefts && audit.elCompanyTefts.length > 0 && (
                <ContentWrapper>
                    <AuditThemesList audit={audit} />
                </ContentWrapper>
            )}

            {isFirstStageDocRequest(audit) && !!audit.metaData?.documentationRequest && (
                <ContentWrapper>
                    <ContentSectionHeading>Vi ber om at følgende dokumenter sendes inn:</ContentSectionHeading>
                    <ul>
                        {audit.metaData?.documentationRequest?.split('\n')?.map((comment: string, index: number) => (
                            <li key={index}>{comment}</li>
                        ))}
                    </ul>
                </ContentWrapper>
            )}

            {isSecondStageDocRequest(audit) && !!audit.metaData?.additionalDocumentationRequest && (
                <ContentWrapper>
                    <ContentSectionHeading>Vi ber om at følgende dokumenter sendes inn:</ContentSectionHeading>
                    <ul>
                        {audit.metaData?.additionalDocumentationRequest
                            ?.split('\n')
                            ?.map((comment: string, index: number) => (
                                <li key={index}>{comment}</li>
                            ))}
                    </ul>
                </ContentWrapper>
            )}
        </div>
    )
}
