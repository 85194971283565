
export type Audit = {
    id: string
    caseNumber: string
    type: string
    additionalType: string
    status: string
    comment: string
    created: Date
    targets: any[]
    contacts: any[]
    metaData: any
    complexErrorStructure:ComplexErrorStructure
    elCompanyTefts: string[]
    formBuilderData: string
}
type ComplexErrorStructure = {
    id:string
    categories:Category[]
}
type Category = {
    id:string,
    name:string
    fieldName:string
}
export enum AuditType {
    FIRE_OWNER = 'specificfireowner',
    FIRE_USER = 'specificfireuser',
    MAPPING = 'mapping',
    REVISION = 'revision',
    EL_REVISION = 'elrevision',
    OTHER_CASE = 'other_case',
}

export enum AdditionalAuditType {
    POSTAL_AUDIT = 'postal',
}

export enum AuditStatus {
    ACTIVE = 'active',
    APPROVED = 'approved',
    DONE = 'done',
    CANCELLED = 'cancelled',
    RESOLUTION = 'resolution',
    APPROVED_NO_ERRORS = 'approvednoerrors',
    MISSED = 'missed',
    DISCONNECTED = 'disconnected',
    APPROVED_WITH_ERRORS = 'approvedwitherrors',
}

export enum AuditMetaDataStatus {
    NONE = 'NONE',
    REQUEST_SENT = 'REQUEST_SENT',
    DOCUMENTATION_RECEIVED = 'DOCUMENTATION_RECEIVED',
    ADDITIONAL_REQUEST_SENT = 'ADDITIONAL_REQUEST_SENT',
}
